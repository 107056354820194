import React, {useState} from 'react'
import {firestoreDb } from "../../firebase";
import {
  doc,
  setDoc,

} from "firebase/firestore";
import { useTranslation } from 'react-i18next';

// import firebase from 'firebase/app'
export default function ContactUs() {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let data = {email: email, comment: comment}
      await setDoc(doc(firestoreDb, "comments", email), data);
      setEmail('');
      setComment('');
      // alert('Message submitted successfully!');
    } catch (error) {
      console.error('Error saving message to Firestore: ', error);
      alert('Error submitting message. Please try again later.');
    }
  };


  return (
 <div className='py-5 bg-white'>
      <form className='flex flex-col items-center justify-center' onSubmit={handleSubmit}>
        <h1 className="hero-how -mb-[10vh]">{t('getintouch')}</h1>
        <div className="flex flex-col justify-center items-center mt-[100px]">
          <input
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="px-[20vw] md:px-[20vw] rounded-md py-[2vh] bg-[#ececec]"
            placeholder={t('emailenter')}
            required
          />
          <br />
          <textarea
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
            placeholder={t('question')}
            className="px-[20vw] md:px-[20vw] border-none outline-none font-[Montserrat-Regular] rounded-md py-[10vh] bg-[#ececec]"
          ></textarea>
          <br />
          <button type="submit" className="font-[Montserrat-Bold] text-2xl ml-[10%] bg-[#8d0ff5] text-white px-5 py-4 mr-7 rounded-xl invisible md:visible w-0 md:w-auto shadow-md shadow-[#2f2f2f]">{t('submit')}</button>
          <br />
        </div>
      </form>
    </div>
  )
}
