import React, { useState } from 'react'
import { firestoreDb } from "../../firebase";
import {
    doc,
    collection,
    setDoc,

} from "firebase/firestore";
import smile from '../../images/smily.png';
import { useTranslation } from 'react-i18next';

export default function Contact() {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const { t, i18n } = useTranslation();


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            let data = { 'email': email, 'phone': phone }
            await setDoc(doc(firestoreDb, "others", email), data);
            setEmail('');
            setPhone('');
            // alert('Message submitted successfully!');
        } catch (error) {
            console.error('Error saving message to Firestore: ', error);
            alert('Error submitting message. Please try again later.');
        }
    };

    return (
        <div className='py-5 bg-white'>
            <form className='flex flex-col items-center justify-center' onSubmit={handleSubmit}>
                <div className='flex flex-col  mt-[15vh] '>
                    <img src={smile} className='w-[100px] h-[100px] mt-5 mb-2'/>
                    <div className='flex flex-col items-start w-[100%]'>
                        <h1 className=" text-3xl text-[#8e00ff] font-[Montserrat-Bold]">{t('notavailable')}</h1>
                        <p className='font-[Montserrat-Bold]'>{t('questionabout')} </p>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center mt-[100px] md:mt-[20px]">
                    <input
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="px-[20vw] md:px-[10vw] rounded-md py-[2vh] bg-[#ececec]"
                        placeholder={t('emailenter')}
                        required
                    />
                    <br />
                    <input
                        name="phone"
                        type="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="px-[20vw] md:px-[10vw] rounded-md py-[2vh] bg-[#ececec]"
                        placeholder={t('phoneenter')}
                        required
                    />
                    <br />
                    <button type="submit" className="header-download">{t('submit')}</button>
                </div>
            </form>
        </div>
    )
}