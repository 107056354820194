import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Featured() {
    const { t, i18n } = useTranslation();

    return (
        <div className="flex md:flex-row justify-between  px-5 md:px-20 pt-20 pb-20 items-center flex-col ">
            <div className="text-white font-[Montserrat-Bold]">
                <div className="text-4xl font-[Montserrat-Bold] text-center" >
                    {t('audiance')}
                </div>
                <div className="flex flex-col md:flex-row items-center justify-around pb-10">
                    <div className="flex flex-col justify-center items-center">
                        <div className="w-[100%]">
                            <img src={require('../../images/play-icon.webp')} />
                        </div>
                        <div className="text-4xl font-[Montserrat-Bold]">
                            {t('play')}
                        </div>
                        <div className="text-center max-w-[100%] min-w-[100%] text-2xl font-[Montserrat-Regular]">
                            {t('playbody')}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="w-[100%]">
                            <img src={require('../../images/battle-icon.webp')} />
                        </div>
                        <div className="text-4xl font-[Montserrat-Bold]">
                            {t('battle')}
                        </div>
                        <div className="text-center  max-w-[100%] min-w-[100%] text-2xl font-[Montserrat-Regular]">
                            {t('battlebody')}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="w-[80%]">
                            <img src={require('../../images/host-icon.webp')} />
                        </div>
                        <div className="text-4xl font-[Montserrat-Bold]">
                            {t('host')}
                        </div>
                        <div className="text-center max-w-[80%] min-w-[80%] text-2xl font-[Montserrat-Regular]">
                            {t('hostbody')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
