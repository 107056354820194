import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <div className="py-10">
    <div className="social-set">
      {/* <a href='#' target='_blank' className="social-icon facebook-icon"></a> */}
      <a href='https://twitter.com/beemiapp' target='_blank' className="social-icon twitter-icon"></a>
      <a href='https://instagram.com/beemiapp' target='_blank'className="social-icon instagram-icon"></a>
      <a href='https://youtube.com/@beemiapp' target='_blank' className="social-icon youtube-icon"></a>
      <a href='https://tiktok.com/@beemiapp' target='_blank' className="social-icon tiktok-icon"></a>
      <a href='https://linkedin.com/company/beemiapp' target='_blank' className="social-icon linkedin-icon"></a>
    </div>
    <div className="text-center text-white font-[Montserrat-Regular]">
      <div className="text-lg font-[Montserrat-Bold]">
        +251910184144 | +251910994481
      </div>
      <div className="text-lg font-[Montserrat-Bold]">
        support@beemi.app
        <br />
        <br />
      </div>
      <div className="text-xm font-[Montserrat-Regular]">
       {t('disclaimer')}
      </div>
    </div>
  </div>
  )
}
