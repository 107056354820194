import React, {useState, useEffect, useRef} from 'react'
import './App.css';
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/pages/About";
import Header from "./components/Header";
import Layout from './components/Layout';
import appleAppSiteAssociationData from './.well-known/assetlinks.json'; // Import the JSON data
import Contact from './components/pages/Contact';





function App() {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const ticking = useRef(false); // Use useRef to avoid unnecessary re-renders

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset || window.scrollY;

      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          if (prevScrollPos > currentScrollPos) {
            document.getElementById("header").style.top = "0px"; // Show navbar when scrolling up
          } else {
            document.getElementById("header").style.top = "-100px"; // Hide navbar when scrolling down
          }
          setPrevScrollPos(currentScrollPos);
          ticking.current = false;
        });
        ticking.current = true;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]); // Keep prevScrollPos as dependency

  return (
    <Layout >
      <Header />
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/about" element={<About />}>
          </Route>
          <Route path="/contact" element={<Contact />}>
          </Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </Layout >
  );
}

export default App;
