
import React from 'react'
import Hero from '../Elements/Hero'
import tiktok from '../VedioHero/tiktok.svg'
import twitch from '../VedioHero/19.png'
import youtube from '../VedioHero/youtube.png'
import CenterElement from '../Elements/CenterElement';
import Featured from '../Elements/Featured';
import Download from '../Elements/Download';
import ProfitHoder from '../Elements/ProfitHoder';
import ContactUs from './Contact-Us';
import VedioHeader from '../VedioHero';
import { useTranslation } from 'react-i18next';

export default function Home() {
    const { t, i18n } = useTranslation();
    const userLanguage = navigator.language || navigator.userLanguage;

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); // Switch language
    };
    React.useEffect(() => {
        console.log("language is ", i18n.language);
        
        if (userLanguage.startsWith("am")) {
            i18n.changeLanguage('am')
        } else if (userLanguage.startsWith("en")) {
            i18n.changeLanguage('en')
        } else {
            console.log("Defaulting to English");
        }
    }, [])
    return (
        <div className='w-[100vw] !xl:max-w-[1800px] text-center overflow-x-hidden scroll-smooth'>
            <Hero />
            <ProfitHoder />
            {/* <CenterElement /> */}

            <div className='' id='how'>
                {[
                    {
                        title: t('tiktok'),
                        body: t('tiktokbody'),
                        icon: tiktok,
                        vedio: t('vediotik'),
                        link: 'vediotik'
                    }, {
                        title: t('twich'),
                        body: t('twichbody'),
                        icon: twitch,
                        vedio: t('vediotwi'),
                        link: 'vediotwi'

                    }, {
                        title: t('youtube'),
                        body: t('youtubeBody'),
                        icon: youtube,
                        vedio: t('vedioyou'),
                        link: 'vedioyou'

                    }].map((item, index) => {
                        return <VedioHeader key={index} {...item} />
                    })}
            </div>
            <Featured />
            <Download id='download' />
            <ContactUs />
        </div>
    )
}
