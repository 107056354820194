import React from 'react'
import play from '../../images/play.svg'
import imgPhone from '../../images/phone.png'
import imgOne from '../../images/laptop.png'
import { useParallax } from 'react-scroll-parallax';
import pices from '../../images/torus-2.webp'
import { useTranslation } from 'react-i18next';



const HeroElement = () => {
    return (
        <div className="w-[100vw] md:w-[50%] md:p-20">
            <img src={imgOne} className="absolute md:w-[40vw] md:left-[50vw] left-0 top-[10vh] md:top-[25vh]" />
            <img src={imgPhone} className=" absolute w-[50%] top-[20vh] md:w-[25vw] right-[3vw] md:top-[34vh]" />
        </div>
    )
}
const Explore = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="w-[100%]   flex flex-col px-5 md:p-20 mt-[90vw] md:mt-0">
            <p className="text-4xl text-white font-[Montserrat-Bold] md:text-left text-center md:max-w-[80%]">
                {t('engage')}</p>
            <div className="flex md:flex-row flex-col justify-center md:justify-around items-center pt-10 w-[100%] md:w-[50vw]">
                <a href="#download" className='bg-[#8d0ff5] px-10 py-5 font-[Montserrat-Bold] text-2xl text-white rounded-[200px] shadow-md shadow-[#2f2f2f] my-5'>{t('download')}</a>
                <a className="flex flex-row justify-center md:justify-center items-center pt-10 md:pt-0 w-[100vw]">
                    <img src={play} className='w-[30px] h-[30px] ml-5 -mr-32' />
                    <a href="#how" className="text-sm font-[Montserrat-Bold] ml-5 md:ml-0 text-white min-w-[30vw]">{t('seehowitworks')}</a>
                </a>
            </div>
        </div>
    )
}


export default function Hero() {

    const pice = useParallax({
        translateY: [-500, 300],
    });
    return (
        <div className="min-h-[100vh] px-[5vw] flex md:flex-row md:justify-around flex-col  xl:max-w-[1800px] md:items-center" >
            <img className="w-[15vw] absolute left-0 -ml-[5vw] -z-10" ref={pice.ref} src={pices} />
            <Explore />
            <HeroElement />
        </div>
    )
}
