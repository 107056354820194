import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import translationEN from './locales/en/translation.json';
import translationFR from './locales/am/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  am: {
    translation: translationFR,
  },
};

i18n
  .use(Backend) // Load translation using http backend
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    resources,
    lng: 'am', // Default language
    fallbackLng: 'am', // Fallback language if the translation is not available
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
