import React, { useEffect, useState } from 'react';
import YouTubeStream from '../pages/Youtube';
import { useTranslation } from 'react-i18next';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';



export default function VedioHeader({ icon, title, body, link, vedio }) {
    const { t, i18n } = useTranslation();
    const [vedioLink, setVedioLink] = useState(vedio)
    const [languageFile, setLanguage] = useState(i18n.language)

    const getSpecificTranslation = (key, language) => {
        // Get the translation data for the specified language
        const languageData = i18n.getDataByLanguage(language);

        // Access the specific key
        const translation = languageData?.translation?.[key];

        return translation || `No translation found for key: ${key}`;
    };

    const handleLanguageChange = (item) => {
        i18n.changeLanguage(item)
    };


    const renderer = item => {
        if (item == 'en') {
            return "Amharic"
        }
        else if (item == "am") {
            return "English"
        }
        else {
            return "English"
        }

    }
    useEffect(() => {

    }, [vedioLink, languageFile])

    return (
        <div className='flex flex-col  items-center my-0 w-[100%] bg-[#7329BE]' >
            <div className='flex flex-row items-center py-5 justify-around'>
                <img src={icon} className='w-52' />
                <div className='flex flex-col ml-5'>
                    <h1 className='text-4xl font-[Montserrat-Bold] text-white'>{title}</h1>
                    <p className='text-white max-w-[70%] mt-5 font-[Montserrat-Regular]'>{body}</p>
                </div>
            </div>
            <div className='bg-[#913CC8] w-[100vw] flex flex-col items-center py-14 overflow-hidden'>
                <YouTubeStream videoId={vedio} title={title} />
                <div className='absolute bg-[white] px-2 rounded-sm mt-[15%] right-[5%] shadow-md cursor-pointer '>
                    {i18n.language == 'en' ? <a onClick={(e) => handleLanguageChange('am')}>
                        <Space>
                            <h1 className='!font-[Montserrat-Bold] text-[#8d0ff5]'>{renderer(i18n.language)} <RightOutlined className='font-extrabold' /></h1>
                        </Space>
                    </a> : <a onClick={(e) => handleLanguageChange('en')}>
                        <Space>
                            <h1 className='!font-[Montserrat-Bold] text-[#8d0ff5]'>{renderer(i18n.language)} <RightOutlined className='font-extrabold' /></h1>
                        </Space>
                    </a>}
                </div>

            </div>

        </div>
    );
} 
